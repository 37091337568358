const collator = new Intl.Collator([], { numeric: true })

export const sortChapters = <T extends { frontmatter: { index: number } }>(
  a: T,
  b: T
) => {
  return collator.compare(
    a.frontmatter.index.toString(),
    b.frontmatter.index.toString()
  )
}
