import React, { useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"
import { blueTheme } from "../../styles/themes"
import { Typography } from "../system/typography"
import { FlexRow } from "../system/flex-row"
import { FlexColumn } from "../system/flex-column"
import * as Styled from "./styles"
import { useRefEffect } from "../../hooks/useRefEffect"
import { usePageContext } from "../../context/page"

const EndChapter: React.FC = () => {
  const { id } = usePageContext()
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const element = document.getElementById(`page__container--${id}`)
    if (element) setContainer(element)
  }, [id])

  const [_, setRef] = useRefEffect<HTMLDivElement>(
    node => {
      const observer = new IntersectionObserver(
        entries => {
          const entry = entries[0]
          if (!entry) return

          const event = new CustomEvent<{ visible: boolean; pageId: string }>(
            "footer-observer",
            {
              detail: {
                visible: entry.isIntersecting,
                pageId: id,
              },
            }
          )

          window.dispatchEvent(event)
        },
        { threshold: [0.92], root: container }
      )

      observer.observe(node)
      return () => observer.unobserve(node)
    },
    [container]
  )

  return (
    <ThemeProvider theme={blueTheme}>
      <Styled.Footer ref={setRef}>
        <Styled.FooterContent>
          <FlexRow>
            <FlexColumn flex={{ xs: 0, xxl: 1 }} />
            <FlexColumn flex={{ xs: 1, sm: 1, md: 1, lg: 5, xl: 4, xxl: 3 }}>
              <Typography
                as="h1"
                variant="h1"
                style={{ cursor: "pointer" }}
                mb={{
                  xs: "6rem",
                  sm: "6rem",
                  md: "6rem",
                  lg: "7rem",
                  xl: "9rem",
                  xxl: "23rem",
                }}
              >
                Ukończono 100% książki.
              </Typography>
              <Styled.LinkContainer
                as="a"
                href="http://vnlab.filmschool.lodz.pl/"
                target="_blank"
                aria-label="vnLab"
              >
                <Styled.VnLabIcon />
              </Styled.LinkContainer>
            </FlexColumn>
            <FlexColumn flex={{ xs: 0, sm: 0, md: 0, lg: 4, xl: 8, xxl: 8 }} />
          </FlexRow>
        </Styled.FooterContent>
        <Styled.FooterDivider variant="white" />
      </Styled.Footer>
    </ThemeProvider>
  )
}

export default EndChapter
