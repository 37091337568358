import * as React from "react"
import MarginImages from "../margin-images"
import * as Styled from "./styles"

type Props = {
  pageId: string
  noPadding?: boolean
}

const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  pageId,
  noPadding = false,
}) => (
  <Styled.ContentWrapper $noPadding={noPadding}>
    <Styled.Spacer />
    <Styled.Text as="main" id={`main--${pageId}`}>
      {children}
    </Styled.Text>
    <MarginImages pageId={pageId} />
    <Styled.Spacer
      id={`footnotes__uhd--${pageId}`}
      style={{ position: "relative" }}
    />
  </Styled.ContentWrapper>
)

export default PageLayout
