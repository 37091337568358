import { between, down, only, up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import { FlexColumn } from "../system/flex-column"
import { FlexRow } from "../system/flex-row"

export const ContentWrapper = styled(FlexRow)<{ $noPadding?: boolean }>`
  padding: 0px 3.2rem;
  padding-bottom: 7rem;
  background: ${({ theme }) => theme.colors.ivoryWhite};

  ${between("md", "xl")} {
    padding: 0px 8.7rem;
    padding-bottom: 11rem;
  }

  ${only("xl")} {
    padding: 0px 16.4rem;
    padding-bottom: 11rem;
  }

  ${up("xxl")} {
    padding: 0px 14rem;
    padding-bottom: 11rem;
  }

  ${({ $noPadding = false }) =>
    $noPadding &&
    css`
      && {
        padding-bottom: 0;
        padding-top: 0 !important;
      }
    `};

  padding-top: calc(0.7 * 5rem);

  ${between("md", "xl")} {
    padding-top: calc(0.83 * 5rem);
  }

  ${up("xl")} {
    padding-top: 5rem;
  }

  ${up("xxl")} {
    padding-top: calc(1.41 * 5rem);
  }
`

export const Spacer = styled(FlexColumn)`
  flex: 2;
  max-width: calc(100% / 12 * 2 - 1.6rem);

  ${down("xxl")} {
    display: none;
  }
`

export const Text = styled(FlexColumn)`
  position: relative;
  flex: 1;

  ${between("lg", "xl")} {
    flex: 6;
    max-width: calc(100% / 9 * 6 - 0.9rem);
  }

  ${only("xl")} {
    flex: 8;
    max-width: calc(100% / 12 * 8 - 1.6rem);
  }

  ${up("xxl")} {
    flex: 5;
    max-width: calc(100% / 12 * 5 - 1.6rem);
  }
`
