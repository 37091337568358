import React, { useEffect, useMemo } from "react"
import { graphql, PageProps } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/page-layout"
import Seo from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import mdxComponents from "../components/mdx"
import AdvancedHeader from "../components/header/advanced"
import { Image, Video } from "../types/config"
import Footnote from "../components/footnote"
import NextChapter from "../components/next-chapter"
import { HypothesisTextBtn } from "../components/hypothesis-btn"
import { PageContextType } from "../context/page"
import { sortChapters } from "../util/sortChapters"
import { filterChapters } from "../util/filterChapters"
import { getChapterNodeTitle } from "../util/getChapterNodeTitle"
import Progress from "../components/header/progress"
import EndChapter from "../components/end-chapter"

interface Data {
  mdx: {
    id: string
    slug: string
    body: string & React.ReactNode
    frontmatter: {
      index: number
      title: string
      images: Image[]
      videos: Video[]
    }
    fields: {
      headings: Array<{
        id: string
        value: string
        depth: number
      }>
    }
  }
  allFootnotes: {
    nodes: Array<{
      id: string
      target: string
      link: string
      content: string
      index: string
    }>
  }
  allMdx: {
    nodes: Array<{
      slug: string
      frontmatter: {
        title: string
        index: number
      }
    }>
  }
}

const Chapter: React.FC<
  PageProps<Data, PageContextType, { position?: number }>
> = ({ data: { mdx, allFootnotes, allMdx }, location, ...props }) => {
  const isStartPage = location.pathname.includes("start")

  const headerTitle = useMemo(() => {
    const index = mdx.slug.match(/\d+/)?.[0] ?? "-1"
    const title = `Rozdział ${index}`
    if (!mdx.frontmatter.title) return title
    if (index === "-1") return mdx.frontmatter.title
    return title.concat(` / ${mdx.frontmatter.title}`)
  }, [mdx.slug, mdx.frontmatter.title])

  const images =
    mdx.frontmatter?.images?.reduce(
      (p, c) => ({ ...p, [c.id]: c }),
      {} as Record<string, Image>
    ) ?? {}

  const videos =
    mdx.frontmatter?.videos?.reduce(
      (p, c) => ({
        ...p,
        [c.id]: c,
      }),
      {} as Record<string, Video>
    ) ?? {}

  const footnote = useMemo(
    () => allFootnotes.nodes.find(f => f.link === location.hash),
    [location.hash]
  )

  const chapters = useMemo(
    () => allMdx.nodes.filter(filterChapters).sort(sortChapters),
    [allMdx]
  )

  const nextChapter = useMemo(() => {
    if (isStartPage) return chapters[0]

    const index = mdx.frontmatter.index
    const currentIndex = chapters.findIndex(c => c.frontmatter.index === index)

    return chapters[currentIndex + 1]
  }, [mdx, chapters, isStartPage])

  useEffect(() => {
    if (!location.hash || location.hash.includes("footnote")) return

    const container = document.getElementById(
      `page__container--${props.pageContext.id}`
    )
    if (!container) return

    const element = container.querySelector(location.hash)
    if (!element) return

    const timeout = setTimeout(
      () => element.scrollIntoView({ behavior: "smooth" }),
      500
    )

    return () => clearTimeout(timeout)
  }, [location.hash, props.pageContext.id])

  useEffect(() => {
    if (!location.state || !location.state.position) return

    const containerId = `page__container--${props.pageContext.id}`
    const container = document.getElementById(containerId)
    if (!container) return

    const timeout = setTimeout(() => {
      container.scrollTo({
        top: location.state.position,
        behavior: "smooth",
      })
    }, 500)

    return () => clearTimeout(timeout)
  }, [location.state, props.pageContext.id])

  return (
    <React.Fragment key={location.pathname}>
      <Seo title={headerTitle} />
      <AdvancedHeader
        title={headerTitle}
        progress={
          <Progress
            chapterIndex={mdx.frontmatter.index}
            chapters={chapters}
            headings={mdx.fields?.headings ?? []}
            files={[
              ...(mdx.frontmatter?.images ?? []),
              ...(mdx.frontmatter?.videos ?? []),
            ]}
          />
        }
        showHypothesisButton
      />
      <Layout pageId={props.pageContext.id} noPadding={isStartPage}>
        <MDXProvider components={mdxComponents}>
          <MDXRenderer
            frontmatter={mdx.frontmatter}
            images={images}
            videos={videos}
          >
            {mdx.body}
          </MDXRenderer>
          <Footnote key={footnote?.id ?? ""} data={footnote} />
        </MDXProvider>
      </Layout>
      {nextChapter ? (
        <NextChapter
          fullPage={isStartPage}
          link={`/${nextChapter.slug}`}
          title={getChapterNodeTitle(nextChapter, ` z ${chapters.length}`)}
          subtitle={nextChapter.frontmatter.title}
        />
      ) : (
        <EndChapter />
      )}
      <HypothesisTextBtn />
    </React.Fragment>
  )
}

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      id
      slug
      body
      frontmatter {
        title
        index
        images {
          id
          alt
          path {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        videos {
          id
          alt
          mp4 {
            publicURL
          }
          gif {
            publicURL
          }
        }
      }
      fields {
        headings {
          value
          depth
          id
        }
      }
    }
    allFootnotes(filter: { parent: { id: { eq: $id } } }) {
      nodes {
        content
        id
        link
        target
        index
      }
    }
    allMdx {
      nodes {
        slug
        frontmatter {
          index
          title
        }
      }
    }
  }
`

export default Chapter
