import { between, down, only, up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import Divider from "../divider"
import { FlexColumn } from "../system/flex-column"
import { FlexRow } from "../system/flex-row"
import ArrowRightSVG from "../../images/arrow-right.inline.svg"
import mdx from "../mdx"

export const Footer = styled(FlexRow).attrs({
  as: "footer",
  flexDirection: "column",
  justifyContent: "flex-end",
})<{ $fullPage?: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.colors.blue};
  height: 100vh;

  ${({ $fullPage = false }) =>
    $fullPage &&
    css`
      height: calc(100vh - var(--header-height));
    `};
`

export const FooterContent = styled(FlexColumn)`
  flex: 1;

  padding: 0px 3.2rem;

  ${between("md", "xl")} {
    padding: 0px 8.7rem;
  }

  ${only("xl")} {
    padding: 0px 16.4rem;
  }

  ${up("xxl")} {
    padding: 0px 14rem;
  }

  ${up("xxl")} {
    flex: 11;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const FooterSpacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("xxl")} {
    display: block;
    flex: 1;
  }
`

export const FooterDivider = styled(Divider)`
  margin-top: 3rem;
  margin-bottom: 5rem;
  margin-left: 1.8rem;
  margin-right: 1.8rem;

  ${between("md", "xl")} {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }

  ${only("xl")} {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
    margin-top: 6rem;
    margin-bottom: 7rem;
  }

  ${up("xxl")} {
    margin-left: 14rem;
    margin-right: 14rem;
    margin-top: 20rem;
    margin-bottom: 10em;
  }
`

export const NextImg = styled.img`
  margin: 0 auto 0 0;
  flex-shrink: 0;
  cursor: pointer;

  ${down("md")} {
    display: none;
  }

  // Vertical tablet
  ${between("md", "lg", "portrait")} {
    margin-top: 2rem;
  }

  ${up("xxl")} {
    height: 6rem;
  }
`

export const ArrowRightIcon = styled(ArrowRightSVG)`
  margin-left: 1rem;
  vertical-align: middle;

  ${up("md")} {
    margin-left: 1.6rem;
  }
  & path {
    fill: currentColor;
  }
`

export const NextChapterLink = styled(mdx.Link)`
  font: inherit;
  font-weight: 500;
  display: inline-flex;
  width: fit-content;
  align-items: center;
`

export const LinkContainer = styled.div`
  margin: 0 0 0.7rem 0;
  height: 2.4rem;
`
