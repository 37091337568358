import { motion } from "framer-motion"
import styled from "styled-components"

import CloseSVG from "../../images/x.inline.svg"
import CopySVG from "../../images/copy.inline.svg"
import { Typography } from "../system/typography"

export const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 0.7 },
  exit: { opacity: 0, transition: { delay: 0.4 } },
  transition: { duration: 0.1, ease: "easeIn" },
})`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.ivoryWhite};
  opacity: 0.8;
`

export const Note = styled(motion.article).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.4, ease: "easeIn", delay: 0.1 },
})`
  pointer-events: all;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.text};
  position: absolute;
  left: 3rem;
  right: 3rem;
`

export const IconButton = styled(Typography).attrs({
  as: "button",
})`
  cursor: pointer;
  margin: 0;
`

export const CloseIcon = styled(CloseSVG)`
  height: 0.75em;
  width: auto;
  max-height: 2.5rem;
`

export const CopyIcon = styled(CopySVG)`
  height: 0.8em;
  width: auto;
  max-height: 2.5rem;
`
