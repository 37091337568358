import { AnimatePresence } from "framer-motion"
import { navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from "react-dom"
import { between, up } from "styled-breakpoints"
import styled, { ThemeProvider } from "styled-components"
import useBreakpoint from "../../hooks/useBreakpoint"
import { blueTheme } from "../../styles/themes"
import { FlexColumn } from "../system/flex-column"
import { FlexRow } from "../system/flex-row"
import { Typography } from "../system/typography"
import ReactMarkdown from "react-markdown"
import * as Styled from "./styles"
import mdx from "../mdx"
import { usePageContext } from "../../context/page"
import { useRefEffect } from "../../hooks/useRefEffect"

const mdxComponents = {
  ...mdx,
  p: styled(Typography).attrs({
    variant: "footnoteContent",
  })``,
  a: styled(Typography).attrs({
    as: mdx.a,
    variant: "footnoteContent",
    target: "_blank",
  })`
    font: inherit;
    color: inherit;
    text-decoration: underline;
    text-underline-position: from-font;

    &:after {
      display: none !important;
    }
  `,
}

type Props = {
  data:
    | {
        id: string
        target: string
        link: string
        content: string
        index: string
      }
    | undefined
}

const Footnote: React.FC<Props> = ({ data }) => {
  const [container, setContainer] = useState<Element | null>(null)
  const [parent, setParent] = useState<Element | null>(null)
  const [isMounted, setIsMounted] = useState(true)
  const [top, setTop] = useState(0)
  const { id } = usePageContext()

  const hasMarginImages = useBreakpoint(between("lg", "xxl"))
  const hasMarginFootnotes = useBreakpoint(up("xxl"))
  const containerTarget = useMemo(() => {
    if (hasMarginFootnotes) return `#footnotes__uhd--${id}`
    return hasMarginImages ? `#margin-images__container--${id}` : `#main--${id}`
  }, [hasMarginImages, hasMarginFootnotes])

  useEffect(() => {
    const containerEl = document.querySelector(containerTarget)
    if (containerEl) setContainer(containerEl)
    if (!data?.index) return

    // TODO: possibly optimize with markdown node id
    const parentEls = document.querySelectorAll(
      `#footnote__anchor--${data.index}`
    )
    let parentEl: Element
    if (parentEls.length === 1) parentEl = parentEls[0]
    else parentEl = parentEls[0]
    if (parentEl) setParent(parentEl)
  }, [data, containerTarget])

  useEffect(() => {
    const listener = () => {
      if (!parent) return

      const { offsetTop, offsetHeight, parentElement } = parent as HTMLElement
      const formattingElement = parentElement?.parentElement
      const isParentTableCell = isTableCell(parentElement)
      const isFormattingPresent = isTableCell(formattingElement)

      let top = offsetTop
      if (isParentTableCell || isFormattingPresent) {
        let element: HTMLElement | null = parent.parentElement

        while (element && element.nodeName !== "TABLE") {
          if (!isValidOffsetElement(element)) {
            element = element.parentElement
            continue
          }

          top += element.offsetTop
          element = element.parentElement
        }

        top += element?.offsetTop ?? 0
      }

      let offset = hasMarginImages || hasMarginFootnotes ? 0 : offsetHeight + 20
      setTop(top + offset)
    }

    listener()
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [parent, hasMarginImages, hasMarginFootnotes])

  const [_, setRef] = useRefEffect<HTMLDivElement>(
    node => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (
            !entry.isIntersecting &&
            isMounted &&
            !!top &&
            !!container &&
            !!data
          )
            close()
        })
      })

      observer.observe(node)
      return () => observer.unobserve(node)
    },
    [isMounted, top, container, data]
  )

  const close = () => {
    setIsMounted(false)
    setTimeout(() => navigate("", { replace: true }), 500)
  }

  const copy = () => {
    if (!data?.content) return

    navigator.clipboard.writeText(data.content)
  }

  if (!container || !data) return null

  return ReactDOM.createPortal(
    <AnimatePresence exitBeforeEnter>
      {isMounted && (
        <ThemeProvider theme={blueTheme}>
          <Styled.Wrapper />
          <Styled.Note ref={setRef} style={{ top }}>
            <FlexRow
              display={{ xs: "flex", sm: "flex" }}
              flexDirection={{ xs: "row", sm: "row" }}
            >
              <FlexColumn p="3.5rem" style={{ wordBreak: "break-word" }}>
                {/* @ts-expect-error */}
                <ReactMarkdown components={mdxComponents}>
                  {data.content}
                </ReactMarkdown>
              </FlexColumn>
              <FlexColumn
                style={{ minWidth: "3.5rem" }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                ml="auto"
              >
                <FlexColumn style={{ minHeight: "3.5rem" }} display="flex">
                  <Styled.IconButton onClick={close}>
                    <Styled.CloseIcon
                      role="img"
                      aria-label="Zamknij przypis"
                      style={{ verticalAlign: "bottom" }}
                    />
                  </Styled.IconButton>
                </FlexColumn>
                <FlexColumn style={{ minHeight: "3.5rem" }}>
                  <Styled.IconButton onClick={copy}>
                    <Styled.CopyIcon role="img" aria-label="Kopiuj przypis" />
                  </Styled.IconButton>
                </FlexColumn>
              </FlexColumn>
            </FlexRow>
          </Styled.Note>
        </ThemeProvider>
      )}
    </AnimatePresence>,
    container,
    data.id
  )
}

export default Footnote

const isTableCell = (element?: Element | null) => {
  if (!element) return false
  return ["TH", "TD"].includes(element.nodeName)
}

const isValidOffsetElement = (element?: Element | null) => {
  if (!element) return false
  return ["TR", "TABLE", "A", "SPAN"].includes(element.nodeName)
}
