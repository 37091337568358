import React, { useCallback, useEffect, useState, useRef } from "react"
import { ThemeProvider } from "styled-components"
import { blueTheme } from "../../styles/themes"
import useBreakpoint from "../../hooks/useBreakpoint"
import { up } from "styled-breakpoints"
import { Typography } from "../system/typography"
import { FlexRow } from "../system/flex-row"
import { FlexColumn } from "../system/flex-column"
import * as Styled from "./styles"
import { useRefEffect } from "../../hooks/useRefEffect"
import { navigate } from "gatsby"
import { useHistoryContext } from "../../context/history"
import { usePageContext } from "../../context/page"

import ArrowRight from "../../images/arrow-right.svg"
import useIsClient from "../../hooks/useIsClient"

type Props = {
  fullPage?: boolean
  link: string
  subtitle: string
  title?: string
}

const NextChapter: React.FC<Props> = ({
  fullPage,
  link,
  subtitle,
  title = "",
}) => {
  const { isClient } = useIsClient()

  const showIcon = useBreakpoint(up("lg"))
  const animateIcon = useBreakpoint(up("xl"))
  const { push } = useHistoryContext()

  const handleNavigate = useCallback(() => {
    navigate(link, { state: { forward: true } })
    push(link)
  }, [push])

  const NextCTA = React.useMemo(() => {
    if (!isClient) return null

    if (showIcon) {
      return (
        <Styled.NextImg
          src={ArrowRight}
          alt="dalej"
          aria-label="Dalej"
          tabIndex={0}
          role="button"
          onClick={handleNavigate}
          onKeyDown={e => {
            if (e.key === "Enter") handleNavigate()
          }}
        />
      )
    }

    return (
      <Typography
        variant="caption"
        fontWeight="normal"
        style={{ cursor: "pointer" }}
      >
        <Styled.NextChapterLink
          as="span"
          role="link"
          data-href={link}
          tabIndex={0}
          onClick={handleNavigate}
          onKeyDown={e => {
            if (e.key === "Enter") handleNavigate()
          }}
        >
          Przejdź dalej
          <Styled.ArrowRightIcon />
        </Styled.NextChapterLink>
      </Typography>
    )
  }, [showIcon, animateIcon, handleNavigate, isClient])

  const { id } = usePageContext()
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const element = document.getElementById(`page__container--${id}`)
    if (element) setContainer(element)
  }, [id])

  const [_, setRef] = useRefEffect<HTMLDivElement>(
    node => {
      const observer = new IntersectionObserver(
        entries => {
          const entry = entries[0]
          if (!entry) return

          const event = new CustomEvent<{ visible: boolean; pageId: string }>(
            "footer-observer",
            {
              detail: {
                visible: entry.isIntersecting,
                pageId: id,
              },
            }
          )

          window.dispatchEvent(event)
        },
        { threshold: [0.92], root: container }
      )

      observer.observe(node)
      return () => observer.unobserve(node)
    },
    [container]
  )

  return (
    <ThemeProvider theme={blueTheme}>
      <Styled.Footer ref={setRef} $fullPage={fullPage}>
        <Styled.FooterContent>
          <FlexRow>
            <FlexColumn flex={{ xs: 0, xxl: 1 }} />
            <FlexColumn flex={{ xs: 1, sm: 1, md: 1, lg: 5, xl: 4, xxl: 3 }}>
              <Typography
                as="h2"
                variant="h3"
                fontWeight={500}
                style={{ textTransform: "uppercase" }}
              >
                {title}
              </Typography>
              <Typography
                as="h1"
                variant="h1"
                role="link"
                data-href={link}
                tabIndex={0}
                onClick={handleNavigate}
                onKeyDown={e => {
                  if (e.key === "Enter") handleNavigate()
                }}
                style={{ cursor: "pointer" }}
                mb={{
                  xs: "6rem",
                  sm: "6rem",
                  md: "6rem",
                  lg: "7rem",
                  xl: "9rem",
                  xxl: "23rem",
                }}
              >
                {subtitle}
              </Typography>
              <Styled.LinkContainer>{NextCTA}</Styled.LinkContainer>
            </FlexColumn>
            <FlexColumn flex={{ xs: 0, sm: 0, md: 0, lg: 4, xl: 8, xxl: 8 }} />
          </FlexRow>
        </Styled.FooterContent>
        <Styled.FooterDivider variant="white" />
      </Styled.Footer>
    </ThemeProvider>
  )
}

export default NextChapter
