export const getChapterNodeTitle = <
  T extends {
    frontmatter: {
      index: number
      title: string
    }
  }
>(
  node: T,
  append: string = ""
) => {
  const { index } = node.frontmatter

  return index < 1 ? "" : `Rodział ${index.toFixed()}${append}`
}
