import React from "react"
import * as Styled from "./styles"

type Props = {
  pageId: string
}

const MarginImages: React.FC<Props> = ({ pageId }) => (
  <Styled.Images id={`margin-images__container--${pageId}`} as="aside" />
)

export default MarginImages
