import { between, down, only, up } from "styled-breakpoints"
import styled from "styled-components"
import { FlexColumn } from "../system/flex-column"

export const Images = styled(FlexColumn)`
  pointer-events: none;
  position: relative;

  ${down("lg")} {
    display: none;
  }

  ${between("lg", "xl")} {
    flex: 3;
  }

  ${only("xl")} {
    flex: 4;
  }

  ${up("xxl")} {
    flex: 3;
  }
`
