import React, { useState, useCallback, useEffect } from "react"
import { usePageContext } from "../../context/page"
import { useProgress } from "../../hooks/useProgress"
import { Typography } from "../system/typography"
import debounce from "lodash/debounce"
import styled from "styled-components"
import { between, down, up } from "styled-breakpoints"
import { Image, Video } from "../../types/config"

interface Props {
  chapterIndex: number
  chapters: Array<{
    slug: string
    frontmatter: {
      title: string
      index: number
    }
  }>
  headings: Array<{
    id: string
    value: string
    depth: number
  }>
  files: (Image | Video)[]
}

const Progress: React.FC<Props> = ({
  chapters,
  headings,
  files: images,
  chapterIndex,
}) => {
  const { id } = usePageContext()
  const [progress, setProgress, getProgress] = useProgress()

  const [container, setContainer] = useState<HTMLElement | undefined>(undefined)
  const [elements, setElements] = useState<NodeListOf<Element> | undefined>(
    undefined
  )

  const listener = useCallback(
    debounce(
      () => {
        if (!container) return

        const index = chapters.findIndex(
          c => c.frontmatter.index === chapterIndex
        )
        const baseCompletion = index <= 0 ? 0 : index / chapters.length
        const percentageStep = 1 / 6
        const chapterCompletion =
          container.scrollTop /
            (container.scrollHeight - container.clientHeight) || 0
        const completion = baseCompletion + percentageStep * chapterCompletion

        const baseProgress = getProgress()
        setProgress({
          ...baseProgress,
          link: location.pathname,
          completion: Math.floor(completion * 100),
          position: container.scrollTop,
        })
      },
      200,
      { maxWait: 1000 }
    ),
    [container, chapters, chapterIndex]
  )

  useEffect(() => {
    if (!container) return

    const timeout = setTimeout(() => {
      listener()
      container.addEventListener("scroll", listener)
    }, 525)

    return () => {
      clearTimeout(timeout)
      container.removeEventListener("scroll", listener)
    }
  }, [container, listener])

  useEffect(() => {
    if (!elements || !container) return

    const observer = new IntersectionObserver(
      entries => {
        const baseProgress = getProgress()

        for (let i = 0; i < entries.length; i++) {
          const entry = entries[i]
          if (entry.isIntersecting && !entries[i + 1]?.isIntersecting) {
            const nextProgress = { ...baseProgress }

            if (entry.target.localName === "span") {
              if (baseProgress.heading === entry.target.id) return

              nextProgress.heading = entry.target.id
            } else {
              if (baseProgress.image === entry.target.id) return

              nextProgress.image = entry.target.id
            }

            setProgress(nextProgress)
          }
        }
      },
      {
        root: container,
      }
    )

    const timeout = setTimeout(() => {
      elements.forEach(el => observer.observe(el))
    }, 525)

    return () => {
      clearTimeout(timeout)
      elements.forEach(el => observer.unobserve(el))
    }
  }, [elements, container])

  useEffect(() => {
    const pageContainer = document.getElementById(`page__container--${id}`)
    if (!pageContainer) return

    setContainer(pageContainer)
    let timeout: NodeJS.Timeout
    const baseProgress = getProgress()
    if (headings.length === 0 && images.length === 0) {
      timeout = setTimeout(() => {
        setProgress({ ...baseProgress, heading: "", image: "" })
      }, 500)
      return
    }

    const selectors = [...headings, ...images].map(h => `#${h.id}`).join(", ")
    setElements(pageContainer.querySelectorAll(selectors))
    timeout = setTimeout(() => {
      setProgress({
        ...baseProgress,
        heading: headings[0]?.id ?? "",
        image: images[0]?.id ?? "",
      })
    }, 500)

    return () => clearTimeout(timeout)
  }, [id, headings, images])

  return <ProgressText>{progress.completion}% książki</ProgressText>
}

export default Progress

export const ProgressText = styled(Typography).attrs({
  variant: "captionSmall",
  typo: "azo",
})`
  font-size: 1.4rem;
  font-weight: normal;
  margin-right: 4.6rem;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;

  ${between("md", "xl")} {
    font-size: 1.5rem;
  }

  ${up("xl")} {
    font-size: 2rem;
    margin-right: 6.6rem;
  }

  ${up("xxl")} {
    font-size: 3rem;
  }

  ${between("md", "lg")} {
    position: relative;
    margin-right: 0;
  }

  ${down("md")} {
    visibility: visible;
    position: relative;
    margin-right: 0;
  }
`
